import React, { Component } from 'react'

interface Props {
  style?: React.CSSProperties
}

interface State {}

export default class Text extends Component<Props, State> {
  render() {
    return <p style={this.props.style}>{this.props.children}</p>
  }
}
