import React, { useEffect, useState } from 'react'
import { Form, ModalCenter, Page, ProductCard, Spinner } from 'components'
import api from 'shared/api'
import { getMatchParams, navigate } from 'shared/router'
import AppStore from 'shared/AppStore'
import { Product, User } from 'types'
import styled from 'styled-components'
import { toast } from 'react-toastify'

export default function MailOrder(props: any) {
  const [customer, setcustomer] = useState<User | undefined>()
  const { username } = getMatchParams(props)
  const [loading, setloading] = useState(true)
  const [showShare, setshowShare] = useState(false)
  const [showNote, setshowNote] = useState(false)
  const [showProduct, setshowProduct] = useState<Product | undefined>(undefined)

  const refreshCustomer = async () => {
    if (window.location.pathname.includes('/mail-order/')) {
      let customer = await api.get<User>(`users/username/${username}`)
      if (customer.ok) {
        setloading(false)
        setcustomer(customer.data)
      }

      setTimeout(() => {
        refreshCustomer()
      }, 3000)
    }
  }

  // Use Title and Wrapper like any other React component – except they're styled!
  useEffect(() => {
    window.scrollTo(0, 0)
    async function start() {
      if (username) {
        if (AppStore.loggedUser && AppStore.loggedUser?.role === 'sale') {
          refreshCustomer()
          return
        }
      } else {
        if (AppStore.loggedUser) {
          setloading(false)
          setcustomer(AppStore.loggedUser)
          return
        }
      }
      return navigate('/')
    }

    start()
    // eslint-disable-next-line
  }, [username])

  if (loading) return <Spinner center />

  const onSaveNote = async (data: any) => {
    customer!.note = data.note
    let res = await api.put(`users/${customer!.id}`, customer)
    if (res.ok) {
      setshowNote(false)
      toast.success('Note saved successfully.')
    } else {
      toast.error('Cannot save this note.')
    }
  }

  const sendMail = async (data: any) => {
    let res = await api.post(`users/sendWishlist`, { username: username, email: data['email-address'] })
    if (res.ok) {
      toast.success('Email sent successfully.')
      setshowShare(false)
    } else {
      toast.error('Cannot send email.')
    }
  }

  return (
    <Page
      hideBorderHeader
      onBackPress={() => navigate('/customers')}
      title={`Mail Order - ${customer?.username || username}`}
    >
      {AppStore.loggedUser.role === 'sale' && (
        <div style={{ position: 'relative' }}>
          <ShareIcon onClick={() => setshowShare(true)}>
            <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M16.1726 0H2.57764C1.15539 0 0 1.15539 0 2.57764V10.8971C0 12.3164 1.15246 13.4689 2.56885 13.4747V17.2488L7.99392 13.4747H16.1726C17.5948 13.4747 18.7502 12.3193 18.7502 10.8971V2.57764C18.7502 1.15539 17.5919 0 16.1726 0ZM17.6506 10.8971C17.6506 11.7123 16.9878 12.375 16.1726 12.375H7.64789L3.66559 15.1462V12.375H2.57764C1.76242 12.375 1.09968 11.7123 1.09968 10.8971V2.57764C1.09968 1.76242 1.76242 1.09968 2.57764 1.09968H16.1726C16.9878 1.09968 17.6506 1.76242 17.6506 2.57764V10.8971Z"
                fill="black"
              />
              <path d="M13.73 3.8826H5.01758V4.98228H13.73V3.8826Z" fill="black" />
              <path d="M13.73 6.22562H5.01758V7.32529H13.73V6.22562Z" fill="black" />
              <path d="M13.73 8.5687H5.01758V9.66837H13.73V8.5687Z" fill="black" />
            </svg>
          </ShareIcon>
          <NotesIcon onClick={() => setshowNote(true)}>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M14.1385 10.2844C12.8638 10.2844 11.7391 10.9125 11.0362 11.8734L7.52617 10.1156C7.63395 9.75938 7.70893 9.38906 7.70893 9C7.70893 8.61094 7.63395 8.24062 7.52617 7.88437L11.0362 6.12656C11.7391 7.08281 12.8638 7.71563 14.1385 7.71563C16.2661 7.71563 17.9953 5.98594 17.9953 3.85781C17.9953 1.72969 16.2661 0 14.1385 0C12.0109 0 10.2817 1.72969 10.2817 3.85781C10.2817 4.24688 10.3567 4.61719 10.4645 4.97344L6.95444 6.73125C6.2515 5.775 5.12679 5.14219 3.85212 5.14219C1.72924 5.14219 0 6.87187 0 9C0 11.1281 1.72924 12.8578 3.85681 12.8578C5.13148 12.8578 6.25618 12.2297 6.95913 11.2687L10.4691 13.0266C10.3614 13.3828 10.2864 13.7531 10.2864 14.1422C10.2864 16.2703 12.0156 18 14.1432 18C16.2708 18 18 16.2703 18 14.1422C18 12.0141 16.2661 10.2844 14.1385 10.2844ZM14.1385 1.28437C15.5584 1.28437 16.7113 2.4375 16.7113 3.85781C16.7113 5.27344 15.5584 6.43125 14.1385 6.43125C12.7186 6.43125 11.5657 5.27813 11.5657 3.85781C11.5704 2.4375 12.7232 1.28437 14.1385 1.28437ZM3.85681 11.5734C2.43687 11.5734 1.28404 10.4203 1.28404 9C1.28404 7.58437 2.43687 6.42656 3.85681 6.42656C5.27675 6.42656 6.42958 7.57969 6.42958 9C6.42489 10.4156 5.27206 11.5734 3.85681 11.5734ZM14.1385 16.7156C12.7186 16.7156 11.5657 15.5625 11.5657 14.1422C11.5657 12.7266 12.7186 11.5688 14.1385 11.5688C15.5584 11.5688 16.7113 12.7219 16.7113 14.1422C16.7113 15.5625 15.5584 16.7156 14.1385 16.7156Z"
                fill="black"
              />
            </svg>
          </NotesIcon>
        </div>
      )}
      <H1>Wishlist: {customer?.username.toUpperCase() || username.toUpperCase()}</H1>
      <Body>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {customer?.wishlist?.map((product) => (
            <Card
              key={product.name}
              onClick={() => {
                product.wishlist = true
                setshowProduct(product)
              }}
            >
              <Image src={product.media.length > 0 ? product.media[0].url : ''} />
              <p style={{ flex: 1, marginLeft: 20, fontWeight: 500, fontSize: 16 }}>{product.name}</p>
              <div style={{ width: 35, display: 'flex', alignItems: 'center' }}>
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9.5" cy="9.5" r="9.5" fill="black" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.56525 9.13187L6.53161 6.09823L6.09822 6.53161L9.13187 9.56526L6.09807 12.5991L6.53146 13.0324L9.56525 9.99865L12.599 13.0324L13.0324 12.599L9.99864 9.56526L13.0323 6.53162L12.5989 6.09824L9.56525 9.13187Z"
                    fill="white"
                  />
                  <path
                    d="M6.53161 6.09823L6.70838 5.92145L6.53161 5.74467L6.35483 5.92145L6.53161 6.09823ZM9.56525 9.13187L9.38848 9.30865L9.56525 9.48543L9.74203 9.30865L9.56525 9.13187ZM6.09822 6.53161L5.92144 6.35484L5.74467 6.53161L5.92144 6.70839L6.09822 6.53161ZM9.13187 9.56526L9.30864 9.74204L9.48542 9.56526L9.30864 9.38848L9.13187 9.56526ZM6.09807 12.5991L5.92129 12.4223L5.74452 12.5991L5.92129 12.7758L6.09807 12.5991ZM6.53146 13.0324L6.35468 13.2092L6.53146 13.386L6.70823 13.2092L6.53146 13.0324ZM9.56525 9.99865L9.74203 9.82187L9.56525 9.6451L9.38848 9.82187L9.56525 9.99865ZM12.599 13.0324L12.4223 13.2092L12.599 13.386L12.7758 13.2092L12.599 13.0324ZM13.0324 12.599L13.2092 12.7758L13.386 12.599L13.2092 12.4223L13.0324 12.599ZM9.99864 9.56526L9.82186 9.38848L9.64509 9.56526L9.82186 9.74204L9.99864 9.56526ZM13.0323 6.53162L13.2091 6.7084L13.3858 6.53162L13.2091 6.35485L13.0323 6.53162ZM12.5989 6.09824L12.7757 5.92146L12.5989 5.74468L12.4221 5.92146L12.5989 6.09824ZM6.35483 6.275L9.38848 9.30865L9.74203 8.9551L6.70838 5.92145L6.35483 6.275ZM6.275 6.70839L6.70838 6.275L6.35483 5.92145L5.92144 6.35484L6.275 6.70839ZM9.30864 9.38848L6.275 6.35484L5.92144 6.70839L8.95509 9.74204L9.30864 9.38848ZM6.27485 12.7758L9.30864 9.74204L8.95509 9.38848L5.92129 12.4223L6.27485 12.7758ZM6.70823 12.8557L6.27485 12.4223L5.92129 12.7758L6.35468 13.2092L6.70823 12.8557ZM9.38848 9.82187L6.35468 12.8557L6.70823 13.2092L9.74203 10.1754L9.38848 9.82187ZM12.7758 12.8557L9.74203 9.82187L9.38848 10.1754L12.4223 13.2092L12.7758 12.8557ZM12.8556 12.4223L12.4223 12.8557L12.7758 13.2092L13.2092 12.7758L12.8556 12.4223ZM9.82186 9.74204L12.8556 12.7758L13.2092 12.4223L10.1754 9.38848L9.82186 9.74204ZM12.8555 6.35485L9.82186 9.38848L10.1754 9.74204L13.2091 6.7084L12.8555 6.35485ZM12.4221 6.27501L12.8555 6.7084L13.2091 6.35485L12.7757 5.92146L12.4221 6.27501ZM9.74203 9.30865L12.7757 6.27501L12.4221 5.92146L9.38848 8.9551L9.74203 9.30865Z"
                    fill="white"
                  />
                </svg>
              </div>
            </Card>
          ))}
        </div>
        {!!showProduct && (
          <ModalCenter open={!!showProduct} onClose={() => setshowProduct(undefined)}>
            <ProductCard
              closeFn={() => setshowProduct(undefined)}
              product={showProduct}
              setProduct={(product) => {
                customer!.wishlist! = customer?.wishlist?.filter((p) => p.id !== product.id) || []
                setcustomer(customer)
                setshowProduct(undefined)
              }}
              customer={AppStore?.loggedUser?.role === 'sale' ? customer : undefined}
            />
          </ModalCenter>
        )}
        {showNote && (
          <ModalCenter open={showNote} onClose={() => setshowNote(false)}>
            <div style={{ width: '70vw' }}>
              <h2 style={{ textAlign: 'center' }}>Note</h2>
              <Form
                initialValues={{ note: customer!.note }}
                schema={[{ name: 'note', label: '', type: 'textarea', style: { height: '30vh' } }]}
                onSubmit={onSaveNote}
                submitText={'Save'}
              />
              <div style={{ height: 20 }}></div>
            </div>
          </ModalCenter>
        )}
        {showShare && (
          <ModalCenter open={showShare} onClose={() => setshowShare(false)}>
            <div style={{ padding: 20, width: '60vw' }}>
              <H1>Type email address</H1>
              <Form
                schema={[
                  {
                    label: 'Email Address',
                    name: 'email-address',
                    placeholder: '',
                    type: 'email',
                  },
                ]}
                submitText="SEND"
                onSubmit={sendMail}
              />
            </div>
          </ModalCenter>
        )}
      </Body>
    </Page>
  )
}

const H1 = styled.div`
  margin: 20px 25px;
  font-weight: 500;
  font-size: 20px;
  /* or 58px */

  letter-spacing: 0.05em;

  color: #000000;
`

const NotesIcon = styled.div`
  position: absolute;
  top: 13px;
  right: 10px;
  cursor: pointer;
  padding: 10px;
`
const ShareIcon = styled.div`
  position: absolute;
  top: 13px;
  right: 45px;
  cursor: pointer;
  padding: 10px;
`

const Image = styled.img`
  height: 120px;
`

const Card = styled.div<{ selected?: boolean }>`
  display: flex;
  flex-direction: row;
  transition: 0.3s;
  min-height: 100px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  background-color: ${(props) => (props.selected ? '#ccc' : 'transparent')};
  border-bottom: 1px solid #e5e5e5;
  &:first-child {
    border-top: 1px solid #e5e5e5;
  }
`

const Body = styled.div`
  align-self: center;
  width: 600px;
  min-height: 68vh;
  @media (max-width: 600px) {
    width: 100%;
  }
`
