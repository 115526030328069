import React, { Component } from 'react'
import { User } from 'types'
import api from 'shared/api'
import { getQueryParams, navigate, reloadApp } from 'shared/router'
import AppStore from 'shared/AppStore'
import { Form, Page } from 'components'
import { toast } from 'react-toastify'
import styled from 'styled-components'

interface State {
  error: string
  initialValues: any
  guestUser: boolean
}

const Body = styled.div`
  align-self: center;
  width: 600px;
  @media (max-width: 600px) {
    width: 100%;
  }
`

export default class Login extends Component<{}, State> {
  state: State = {
    error: '',
    initialValues: { username: getQueryParams()['username'] || '' },
    guestUser: !!getQueryParams()['username'],
  }

  login = async (data: any) => {
    let { username, password } = data
    if (!username) username = getQueryParams()['username']

    try {
      if (username.includes('@dolcegabbana.it')) {
        this.adfsLogin()
        return
      }

      if (username && !password) {
        this.setState({ guestUser: true })
        return
      }

      if (!username || !password) throw new Error('Please insert Username and Password.')

      const res = await api.post<{ token: string; user: User; error: string }>('/login', {
        username: username,
        password: password,
      })
      if (!res.ok || res.data?.error) return toast.error('Unathorized')

      AppStore.saveLoginType('login')
      AppStore.saveToken(res.data?.token!)
      AppStore.loggedUser = res.data?.user!
      navigate('/')
      reloadApp()
    } catch (error) {
      console.error(error)
      toast.error('Unathorized')
    }
  }

  adfsLogin = () => {
    let url = window.location.origin + '/be/api/login/azure'
    if (window.location.origin.includes('localhost')) {
      url = 'https://dg-tvs-backend.test/api/login/azure'
    }
    window.location.assign(url)
  }

  render() {
    const { initialValues, guestUser } = this.state
    return (
      <Page title="Login">
        <Body>
          <div style={{ marginTop: 100 }}>
            <Form
              initialValues={initialValues}
              submitText={'LOGIN'}
              schema={[
                { name: 'username', label: 'Username', placeholder: 'Username' },
                { name: 'password', label: 'Password', placeholder: 'Password', type: 'password', visible: guestUser },
              ]}
              onSubmit={this.login}
            />
          </div>

          {/* {error && <div style={{ marginTop: 20, color: 'red', textAlign: 'center' }}>{error}</div>} */}
        </Body>
      </Page>
    )
  }
}
