import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import CallEnd from '@material-ui/icons/CallEnd'
import Fab from '@material-ui/core/Fab'
import Tooltip from '@material-ui/core/Tooltip'

import useVideoContext from '../../../hooks/useVideoContext/useVideoContext'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      width: 35,
      height: 20,
      marginRight: 10,
      backgroundColor: '#f44336',
    },
  })
)

export default function EndCallButton() {
  const classes = useStyles()
  const { room, removeLocalVideoTrack } = useVideoContext()

  return (
    <Tooltip
      title={'End Call'}
      onClick={() => {
        removeLocalVideoTrack()
        room.disconnect()
      }}
      placement="top"
      PopperProps={{ disablePortal: true }}
    >
      <Fab className={classes.fab}>
        <CallEnd style={{ fontSize: 14 }} />
      </Fab>
    </Tooltip>
  )
}
