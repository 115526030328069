import React, { useEffect, useState } from 'react'
import { Page, Box } from 'components'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { navigate } from 'shared/router'
import api from 'shared/api'

export type Store = { title: string; image: string; code: string; url: string }

export default function StoreSelector() {
  const { pathname } = useLocation()
  const [loading, setloading] = useState(true)
  const [stores, setstores] = useState<Store[]>([])

  const fetchStores = async () => {
    try {
      let res = await api.get<any[]>(`stores`)
      if (res.data) setstores(res.data)
    } catch (e) {}
    setloading(false)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    fetchStores()
  }, [pathname])

  return (
    <Page title="Home" loading={loading} onBackPress={() => navigate('/')}>
      <Body>
        {stores.length > 0 &&
          stores.map((store) => (
            <Box
              onClick={() => navigate('/virtual-tour/:storecode', { storecode: store.code })}
              title={store.title}
              img={store.image}
            />
          ))}
        {(!stores || stores.length === 0) && <EmptyDiv>No stores founds.</EmptyDiv>}
      </Body>
    </Page>
  )
}

const Body = styled.div`
  align-self: center;
  width: 600px;
  @media (max-width: 600px) {
    width: 100%;
  }
`

const EmptyDiv = styled.div`
  display: flex;
  justify-content: center;
  height: 60vh;
  align-items: center;
`
