import React, { useEffect } from 'react'
import { getMatchParams, navigate, reloadApp } from 'shared/router'
import { Page } from 'components'
import AppStore from 'shared/AppStore'
import api from 'shared/api'

export default function TokenPage(props: any) {
  const { token } = getMatchParams(props)

  useEffect(() => {
    if (token && token !== '') {
      AppStore.saveLoginType('adfs')
      AppStore.saveToken(token)
      navigate('/')
      reloadApp()
    } else {
      api.get('/logout')
      AppStore.removeToken()
      navigate('/')
      reloadApp()
    }
  }, [token])
  return <Page title="Token page"></Page>
}
