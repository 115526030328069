import React, { useEffect, useState } from 'react'
import { Form, ModalCenter, Page } from 'components'
import api from 'shared/api'
import { navigate } from 'shared/router'
import useVideoContext from 'twilio/hooks/useVideoContext/useVideoContext'
import AppStore from 'shared/AppStore'
import { User } from 'types'
import styled from 'styled-components'
import { connectRoom } from 'utils'
import VideoCallIcon from '@material-ui/icons/VideoCall'
import { toast } from 'react-toastify'
import { EmailShareButton, EmailIcon, WhatsappIcon, WhatsappShareButton, LineIcon, LineShareButton } from 'react-share'
import useRoomState from 'twilio/hooks/useRoomState/useRoomState'
import useLocalAudioToggle from 'twilio/hooks/useLocalAudioToggle/useLocalAudioToggle'
import useLocalVideoToggle from 'twilio/hooks/useLocalVideoToggle/useLocalVideoToggle'

export default function Customers() {
  const { connect, startTrack } = useVideoContext()
  const [users, setusers] = useState<User[]>([])
  const [actionModal, setactionModal] = useState<boolean>(false)
  const [searchCustomer, setsearchCustomer] = useState('')
  const [createCustomerModal, setcreateCustomerModal] = useState<boolean>(false)
  const [confirmDelete, setconfirmDelete] = useState<User | undefined>()
  const [editPasswordCustomer, seteditPasswordCustomer] = useState<User | undefined>()
  const [shareLinkCustomer, setshareLinkCustomer] = useState<User | undefined>()
  const [userSelected, setuserSelected] = useState<User | undefined>()
  const roomState = useRoomState()
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle()
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle()

  // Use Title and Wrapper like any other React component – except they're styled!
  useEffect(() => {
    async function start() {
      if (AppStore.loggedUser && AppStore.loggedUser?.role === 'sale') {
        refreshUsers()
      } else {
        return navigate('/')
      }
    }

    start()
    // eslint-disable-next-line
  }, [])

  const joinRoom = async (selectedUser: string) => {
    setactionModal(false)
    if (roomState === 'disconnected') {
      await startTrack()
      await connectRoom(selectedUser, connect)
      if (!isAudioEnabled) toggleAudioEnabled()
      if (!isVideoEnabled) toggleVideoEnabled()
    }
  }
  const refreshUsers = async () => {
    if (window.location.pathname === '/customers') {
      if (!createCustomerModal) {
        let users = (await api.get<User[]>('/users')).data
        if (users) {
          setusers(users)
        }
      }

      setTimeout(() => {
        refreshUsers()
      }, 3000)
    }
  }

  const onCreateCustomer = async (data: any) => {
    let res = await api.post('users', { username: data['new-username'], password: data['new-password'] })
    if (res.ok) {
      let users = (await api.get<User[]>('/users')).data
      if (users) {
        setusers(users)
        setcreateCustomerModal(false)
        toast.success(`Customer ${data['new-username']} created successfully.`)
        return
      }
    } else {
      toast.error('Cannot create this user.')
    }
  }

  const onEditPassword = async (data: any) => {
    let res = await api.put(`users/${userSelected!.id}`, {
      'new-password': data['new-password'],
    })
    if (res.ok) {
      toast.success('Password updated successfully.')
      let users = (await api.get<User[]>('/users')).data
      if (users) setusers(users)
      seteditPasswordCustomer(undefined)
      setuserSelected(undefined)
    } else {
      toast.error('Cannot edit password.')
    }
  }

  const deleteUser = async (user: User) => {
    const res = await api.delete(`users/${user.id}`)
    if (res.ok) {
      setusers(users.filter((u) => u.id !== user.id))
      toast.success('User deleted successfully.')
    } else {
      toast.error('Cannot delete user.')
    }
  }

  let shareLinkUrl = ''
  if (shareLinkCustomer) shareLinkUrl = `${window.location.origin}/login?username=${shareLinkCustomer?.username}`

  return (
    <Page onBackPress={() => navigate('/')} title="Customers">
      <Body>
        <H1>Select the customer</H1>
        <Input
          onChange={async (event) => {
            setsearchCustomer(event.target.value)
          }}
          id={'search_customer'}
          type={'search'}
          placeholder={'Type to search'}
        />
        <div className="separator" />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {(searchCustomer && searchCustomer.length > 0
            ? users.filter((u) => u.username.toLowerCase().includes(searchCustomer.toLowerCase()))
            : users
          ).map((user) => (
            <Card
              key={user.username}
              selected={userSelected?.username === user.username}
              isOnline={user.isOnline}
              onClick={() => {
                setuserSelected(user)
                setactionModal(true)
              }}
            >
              {user.username.toUpperCase()}
              {user.isChat && <VideoCallIcon style={{ position: 'absolute', right: 20 }} />}
            </Card>
          ))}
        </div>
        <AddIcon onClick={() => setcreateCustomerModal(true)}>
          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7 7L-3.49691e-07 7L-3.0598e-07 8L7 8L7 15L8 15L8 8L15 8L15 7L8 7L8 -3.49691e-07L7 -3.0598e-07L7 7Z"
              fill="white"
            />
          </svg>
        </AddIcon>
        <ModalCenter open={createCustomerModal} onClose={() => setcreateCustomerModal(false)}>
          <div style={{ padding: 20, width: '60vw' }}>
            <H1>CREATE CUSTOMER</H1>
            <Form
              schema={[
                { label: 'Username', name: 'new-username', placeholder: '', autoComplete: 'none' },
                {
                  label: 'Password',
                  name: 'new-password',
                  placeholder: '',
                  type: 'password',
                  autoComplete: 'new-password',
                },
              ]}
              submitText="CREATE"
              onSubmit={onCreateCustomer}
            />
          </div>
        </ModalCenter>
        {actionModal && !!userSelected && (
          <ModalCenter open={actionModal && !!userSelected} onClose={() => setactionModal(false)}>
            <div style={{ padding: 20 }}>
              <h2 style={{ textAlign: 'center' }}>Choose option</h2>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Card
                  style={{ width: 180, paddingRight: 10, paddingLeft: 10 }}
                  onClick={() => {
                    setactionModal(false)
                    setshareLinkCustomer(userSelected)
                  }}
                >
                  Share link
                </Card>
                <Card
                  style={{ width: 180, paddingRight: 10, paddingLeft: 10 }}
                  onClick={() => joinRoom(userSelected!.username)}
                >
                  Start Video Call
                </Card>
                <Card
                  style={{ width: 180, paddingRight: 10, paddingLeft: 10 }}
                  onClick={() => navigate('/mail-order/:username', { username: userSelected!.username })}
                >
                  Show Wishlist
                </Card>
                <Card
                  style={{ width: 180, paddingRight: 10, paddingLeft: 10 }}
                  onClick={() => {
                    setactionModal(false)
                    seteditPasswordCustomer(userSelected)
                  }}
                >
                  Edit Password
                </Card>
                <Card
                  style={{ backgroundColor: 'black', width: 180, paddingRight: 10, paddingLeft: 10 }}
                  onClick={() => {
                    setactionModal(false)
                    setconfirmDelete(userSelected)
                  }}
                >
                  Delete user
                </Card>
              </div>
            </div>
          </ModalCenter>
        )}
        {!!confirmDelete && (
          <ModalCenter open={!!confirmDelete} onClose={() => setconfirmDelete(undefined)}>
            <div style={{ padding: 20, width: '60vw' }}>
              <H1>
                Are you sure you want to delete:{' '}
                <div style={{ fontSize: 14 }}>{confirmDelete.username.toUpperCase()}</div>
              </H1>
              <YESNOContainer>
                <Button onClick={() => deleteUser(confirmDelete)}>YES</Button>
                <Button onClick={() => setconfirmDelete(undefined)}>NO</Button>
              </YESNOContainer>
            </div>
          </ModalCenter>
        )}
        {!!editPasswordCustomer && (
          <ModalCenter open={!!editPasswordCustomer} onClose={() => seteditPasswordCustomer(undefined)}>
            <div style={{ padding: 20, width: '60vw' }}>
              <H1>
                Edit password <br />
                Customer: {editPasswordCustomer?.username}
              </H1>
              <Form
                schema={[
                  {
                    label: 'Password',
                    name: 'new-password',
                    placeholder: '',
                    type: 'password',
                    autoComplete: 'new-password',
                  },
                ]}
                submitText="EDIT"
                onSubmit={onEditPassword}
              />
            </div>
          </ModalCenter>
        )}
        {shareLinkCustomer && (
          <ModalCenter open={!!shareLinkCustomer} onClose={() => setshareLinkCustomer(undefined)}>
            <div style={{ padding: 20, width: '60vw', textAlign: 'center' }}>
              <H1>
                Share link <br />
                Customer: {shareLinkCustomer?.username}
              </H1>
              <Input
                onFocus={(e) => e.target.select()}
                style={{ marginRight: 0, marginLeft: 0, width: '96%' }}
                value={shareLinkUrl}
              />
              <EmailShareButton style={{ marginTop: 10 }} url={shareLinkUrl}>
                <EmailIcon />
              </EmailShareButton>
              <LineShareButton style={{ marginLeft: 10, marginRight: 10 }} url={shareLinkUrl}>
                <LineIcon />
              </LineShareButton>
              <WhatsappShareButton url={shareLinkUrl}>
                <WhatsappIcon />
              </WhatsappShareButton>
            </div>
          </ModalCenter>
        )}
      </Body>
    </Page>
  )
}

const Input = styled.input`
  height: 50px;
  width: 90%;
  margin-left: 5%;
  margin-bottom: 10px;
  margin-top: 20px;
  border-width: 1px;
  border-color: #5c5c5c;
  padding-left: 10px;
  :focus {
    outline: black;
  }
`

const H1 = styled.div`
  font-family: Futura;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  /* or 58px */

  text-align: center;
  letter-spacing: 0.05em;
  text-align: center;
  margin-top: 20px;
`

const AddIcon = styled.div`
  position: fixed;
  bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 15px;
  font-size: 40px;
  background-color: black;
  border-radius: 100%;
  height: 50px;
  width: 50px;
`

const YESNOContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  margin-top: 20px;
`

const Card = styled.div<{ selected?: boolean; isOnline?: boolean }>`
  font-size: 14px;
  transition: 0.3s;
  padding-left: 20px;
  padding-right: 20px;
  height: 50px;
  margin: 10px;
  display: flex;
  cursor: pointer;
  color: ${(props) => (props.isOnline ? 'black' : 'white')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: ${(props) => (props.isOnline ? '#c8e6c9' : '#888888')};
`

const Body = styled.div`
  align-self: center;
  width: 600px;
  @media (max-width: 600px) {
    width: 100%;
  }
`

const Button = styled.div`
  display: flex;
  cursor: pointer;
  height: 30px;
  width: 60px;
  background-color: black;
  justify-content: center;
  color: white;
  align-items: center;
`
