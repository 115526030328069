import React, { ReactChildren, ReactChild } from 'react'
import styled from 'styled-components'

interface Props {
  children: ReactChild | ReactChildren
  onClick?: () => void
  style?: React.CSSProperties
}

export default function Button({ children, onClick, style }: Props) {
  return (
    <CustomButton style={style} onClick={onClick}>
      {children}
    </CustomButton>
  )
}

const CustomButton = styled.button`
  margin-top: 10px;
  height: 50px;
  background-color: black;
  color: white;
  font-size: 16px;
  border-color: black;
  cursor: pointer;
  :focus {
    outline: black;
  }
`
