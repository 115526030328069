import React from 'react'
import styled from 'styled-components'

interface Props {
  disabled?: boolean
  title: any
  img?: any
  onClick?: () => void
  dark?: boolean
  backgroundPositionY?: string
}

export default function Box({ title, img, onClick, disabled = false, dark = false, backgroundPositionY = 'center' }: Props) {
  return (
    <Wrapper img={img} disabled={disabled} onClick={onClick} backgroundPositionY={backgroundPositionY}>
      <Title dark={disabled}>{title}</Title>
    </Wrapper>
  )
}

const Title = styled.h1<{ dark: boolean }>`
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 32px;
  color: ${(props) => (props.dark ? 'black' : 'white')};
`


const Wrapper = styled.section<{ disabled: boolean; img: string; backgroundPositionY: string }>`
  height: 240px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: ${(props) => props.backgroundPositionY};
  background-image: ${(props) =>
    props.disabled
      ? `linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.6)), url(${props.img})`
      : `linear-gradient(360deg, #000000 -3.29%, rgba(0, 0, 0, 0) 61.63%), url(${props.img})`};
  display: flex;
  justify-content: center;
  align-items: flex-end;
  cursor: pointer;
  width: 100%;
`
