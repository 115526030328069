import React, { useEffect, useState } from 'react'
import { Form, Page } from 'components'
import { navigate } from 'shared/router'
import styled from 'styled-components'
import api from 'shared/api'
import { toast } from 'react-toastify'
import { sendDataLayerPush } from 'utils'

const H1 = styled.div`
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  margin-top: 20px;
`

const H2 = styled.div`
  text-align: center;
  font-weight: 400;
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
`

const Button = styled.button`
  margin-top: 40px;
  font-weight: 600;
  font-size: 16px;
  background-color: white;
  border: black 1px solid;
  height: 55px;
  width: 200px;
  align-self: center;
`

const PrivacyContainer = styled.div`
  @media (min-width: 600px) {
    height: 100%;
    display: flex;
    align-items: center;
  }
`

export default function BookAppointment() {
  const [res, setres] = useState<any>()
  const [countries, setcountries] = useState<string[]>([])

  useEffect(() => {
    window.scrollTo(0, 0)
    async function initialData() {
      const countries = await api.get<string[]>('/countries')
      if (countries && countries.ok) {
        setcountries(countries?.data || [])
      }
    }
    initialData()
  }, [])

  const onSubmit = async (data: any) => {
    if (!data.country) {
      return toast.error('Country is a mandatory field.')
    }
    let sent = await api.post('appointment/book', data)
    if (sent.ok) {
      sendDataLayerPush({ event: 'vb-appointment', eventAction: 'form' })
      setres(true)
    } else {
      toast.error('Cannot book an appointment.')
    }
  }

  let content = undefined

  if (!res) {
    content = (
      <>
        <H2>BOOK AN APPOINTMENT</H2>
        <H2>
          Please fill in the form to book an appointment with your Client Advisor, who will accompany you
            for a unique shopping experience.
        </H2>
        <Form
          submitText="Book now"
          schema={[
            { name: 'name', label: 'Name & Surname', placeholder: 'Enter your Name & Surname', type: 'input' },
            { name: 'address', label: 'Address', placeholder: 'Enter your address', type: 'input' },
            { name: 'email', label: 'Email', placeholder: 'Enter your email', type: 'input' },
            {
              name: 'country',
              label: 'Country',
              placeholder: 'Select your country',
              type: 'select',
              options: countries,
            },
            { name: 'mobile', label: 'Mobile Number', placeholder: ' Enter your mobile number', type: 'input' },
            { name: 'note', label: 'Notes', placeholder: 'Enter some optional notes', type: 'textarea' },
            {
              name: 'privacy',
              label: (
                <PrivacyContainer>
                  {'I have read and understood the'}
                  <a
                    style={{ paddingLeft: 3, paddingRight: 3, color: 'black' }}
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.dolcegabbana.com/it/servizio-clienti?cid=privacy-policy"
                  >
                    {'Privacy Policy'}
                  </a>
                  {'and I agree to process my personal data.'}
                </PrivacyContainer>
              ),
              type: 'checkbox',
            },
          ]}
          onSubmit={onSubmit}
        />
      </>
    )
  } else {
    content = (
      <>
        <H1 style={{ marginTop: '25vh' }}>THANK YOU</H1>
        <H2>
          Your request has been sent.
          <br />
          You will soon receive an invitation
          <br />
          to attend the video call.
        </H2>
        <Button onClick={() => navigate('/')}>GO BACK TO HOME</Button>
      </>
    )
  }

  return (
    <Page onBackPress={() => navigate('/')} title="Store Locator">
      {content}
    </Page>
  )
}
