import React, { useEffect, useState } from 'react'
import { Page, Spinner } from 'components'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { navigate } from 'shared/router'

const IFrame = styled.iframe<{ loading: boolean }>`
  height: ${(props) => (props.loading ? '0px' : 'calc(100vh - 5px)')};
  border-width: 0px;
  margin-top: -60px;
`

const iframeCustom = (loading: boolean, setloading: (loading: boolean) => void) => (
  <IFrame
    loading={loading}
    onLoad={() => {
      setloading(false)
    }}
    allow="geolocation"
    src="https://boutique.dolcegabbana.com"
  />
)

export default function StoreLocator() {
  const { pathname } = useLocation()
  const [loading, setloading] = useState(true)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <Page onBackPress={() => navigate('/')} title="Store Locator">
      {iframeCustom(loading, setloading)}
      {loading && <Spinner center />}
    </Page>
  )
}
