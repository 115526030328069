import React from 'react'
import Video from 'twilio-video'
import { Container, Link, Typography, Paper, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Page } from 'components'
import MobileDetect from 'mobile-detect'
import AppStore from 'shared/AppStore'

const useStyles = makeStyles({
  container: {
    marginTop: '2.5em',
  },
  paper: {
    padding: '1em',
  },
  heading: {
    marginBottom: '0.4em',
  },
})

export default function ({ children }: { children: React.ReactElement }) {
  const classes = useStyles()

  let md = new MobileDetect(window.navigator.userAgent)

  const getErrorMessage = () => {
    try {
      if (md.os().toLowerCase() === 'ios') {
        if (
          md.userAgent().toLowerCase() !== 'safari' ||
          (md.userAgent().toLowerCase() === 'safari' && md.version('Safari') < 13)
        ) {
          return (
            <>
              This app works in <b>iOS</b> only with <b>Safari with version 13 or major</b>
              <br />
              <br />
              {md.ua}
            </>
          )
        }
      }
      if (md.os().toLowerCase() === 'androidos') {
        if (md.userAgent().toLowerCase() !== 'chrome' && md.userAgent().toLowerCase() !== 'firefox')
          return (
            <>
              This app works in <b>Android</b> only with <b>Chrome or Firefox</b>
              <br />
              <br />
              {md.ua}
            </>
          )
      }
    } catch (ex) {}
    return (
      <>
        Unsafe context, make sure that you are on the "https" certified site and that you are on a secure network or
        check{' '}
        <Link href="https://www.twilio.com/docs/video/javascript#supported-browsers" target="_blank" rel="noopener">
          supported browsers
        </Link>
        <br />
        <br />
        {md.ua}
      </>
    )
  }

  if (!Video.isSupported && AppStore.loggedUser) {
    return (
      <Page title="Unsupported Browser" showMenuButton={false}>
        <Container>
          <Grid container justify="center" className={classes.container}>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>
                <Typography variant="h4" className={classes.heading}>
                  Browser or context not supported
                </Typography>
                <Typography>{getErrorMessage()}</Typography>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Page>
    )
  }

  return children
}
