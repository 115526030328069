import { Geonames, User, VideoModal } from '../types'
import api from './api'
import { changeLanguage } from '../translations/i18n'
import { refresh } from './router'
import { SyncDocument } from 'twilio-sync'

export type Language = 'en' | 'it'

class AppStore {
  loggedUser!: User
  selectedlanguage: Language = 'en'
  disclaimerAccepted: boolean = false
  selectProduct!: (productSKU: string) => void
  virtualTourMove?: (e: any) => void
  openVirtualScreenshot?: (base64Url: string) => void
  videoModal?: (videoModal: VideoModal) => void
  document?: SyncDocument
  roomState: any
  geolocation?: Geonames
  loginType!: 'login' | 'adfs'
  tourLanguage?: string

  async loadInitialData() {
    const token = localStorage.getItem('token')
    this.loginType = (localStorage.getItem('login_type') as 'login' | 'adfs') || 'login'
    this.selectedlanguage = (localStorage.getItem('language') as Language) || 'en'
    this.disclaimerAccepted = localStorage.getItem('disclaimerAccepted') === 'true' || false
    changeLanguage(this.selectedlanguage)

    if (token) {
      try {
        api.setHeader('Authorization', `Bearer ${token}`)
        const res = await api.get<User>('/loggedUser')
        if (res.ok) {
          this.loggedUser = res.data!
          this.refreshActiveTimestamp()
        } else {
          localStorage.removeItem('token')
        }
      } catch (error) {
        localStorage.removeItem('token')
        refresh()
      }
    }
  }

  async refreshActiveTimestamp() {
    let res = await api.post('/active', { isVideoChatting: this.roomState })
    if (!res.ok) {
      console.error(res)
    }
    setTimeout(() => {
      this.refreshActiveTimestamp()
    }, 4 * 1000)
  }

  saveToken(token: string) {
    api.setHeader('Authorization', `Bearer ${token}`)
    localStorage.setItem('token', token)
  }

  removeToken() {
    localStorage.removeItem('token')
  }

  saveLoginType(type: 'login' | 'adfs') {
    localStorage.setItem('login_type', type)
  }

  removeLoginType() {
    localStorage.removeItem('login_type')
  }

  saveLanguage(lang: Language) {
    localStorage.setItem('language', lang)
    this.selectedlanguage = lang
    changeLanguage(this.selectedlanguage)
  }
}

export default new AppStore()
