export const AuthorizedRoutes = {}

export const PublicRoutes = {
  '/': { component: require('./Home').default },
  '/login': { component: require('./Login').default },
  '/store-locator': { component: require('./StoreLocator').default },
  '/store-selector': { component: require('./StoreSelector').default },
  '/history-store': { component: require('./HistoryStore').default },
  '/customers': { component: require('./Customers').default },
  '/virtual-tour/:storecode': { component: require('./VirtualTour').default },
  '/book-appointment': { component: require('./BookAppointment').default },
  '/mail-order/:username': { component: require('./MailOrder').default },
  '/mail-order': { component: require('./MailOrder').default },
  '/notifications': { component: require('./Notifications').default },
  '/token/:token': { component: require('./TokenPage').default },
  '/token/': { component: require('./TokenPage').default },
  '/video': { component: require('./Video').default },
}

type Route = {
  component: React.ComponentClass | React.FC
}

export type routes = keyof typeof AuthorizedRoutes | keyof typeof PublicRoutes

export type RoutesMap = Record<routes, Route>
