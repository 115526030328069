import { Page } from 'components'
import React, { Component } from 'react'
import styled from 'styled-components'

const Body = styled.div`
  align-self: center;
  height: 100%;
  width: 600px;
  @media (max-width: 600px) {
    width: 100%;
  }
`

const Wrapper = styled.section<{ img: string }>`
  height: calc(100vh - 60px);
  background-repeat: no-repeat;
  background-size: cover;
  background-image: ${(props) => `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.8)), url(${props.img})`};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const CenteredText = styled.div`
  color: white;
  text-align: center;
`

export default class OfflinePage extends Component {
  render() {
    return (
      <Page showMenuButton={false}>
        <Body>
          <Wrapper img={require('../assets/placeholderoffline.png')}>
            <CenteredText>
              <strong>STAY TUNED</strong>
              <br />
              <br />
              <br />
              The next Virtual Boutique is coming soon.
              <br />
              <br />
              Stay tuned to live a unique shopping experience.
            </CenteredText>
          </Wrapper>
        </Body>
      </Page>
    )
  }
}
