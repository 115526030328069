import React from 'react'
import styled from 'styled-components'
import { Fade, Modal } from '@material-ui/core'
import ClearMat from '@material-ui/icons/Clear'

export default function ModalCenter({
  open,
  onClose,
  style,
  children,
  title,
  backgroundStyle,
  modalStyle,
}: {
  open: boolean
  onClose: () => void
  style?: any
  children: any
  title?: string
  backgroundStyle?: React.CSSProperties
  modalStyle?: React.CSSProperties
}) {
  return (
    <ModalC
      BackdropProps={{ style: backgroundStyle }}
      open={open}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Fade in={open}>
        <ModalBody style={modalStyle}>
          <div
            style={{
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '0px',
            }}
          >
            {title}
            <ClearIcon onClick={onClose} />
          </div>
          <Body style={style}>{children}</Body>
        </ModalBody>
      </Fade>
    </ModalC>
  )
}

const ModalC = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const ModalBody = styled.div`
  max-height: 90vh;
  overflow-y: scroll;
  max-width: 70vw;
  background-color: white;
  outline: none;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
`

const ClearIcon = styled(ClearMat)`
  font-size: 30px;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 10px;
  cursor: pointer;
`

const Body = styled.div`
  flex: 1;
`
