import { create } from 'apisauce'
import { navigate } from './router'
import config from './config'

const api = create({
  baseURL: config.backend_url,
  timeout: 10000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

api.axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // if on login page respond with 401
    if (error.request.responseURL.includes('/api/login')) {
      return Promise.reject(error)
    } else if (error.response.status === 401) {
      navigate('/login')
    } else {
      return Promise.reject(error)
    }
  }
)

export default api
