import { InputProps } from '@material-ui/core'
import React, { Component } from 'react'
import styled from 'styled-components'

interface Schema extends Partial<InputProps> {
  label: any
  name: string
  placeholder?: string
  type?: string
  options?: string[]
  visible?: boolean
}

interface Props {
  initialValues?: any
  schema: Schema[]
  onSubmit: (data: any) => void
  submitText: string
}

interface State {
  data: any
}
export default class Form extends Component<Props, State> {
  static defaultProps: Partial<Props> = {
    initialValues: {},
  }
  state: State = { data: {} }

  handleSubmit = (event: any) => {
    const { data } = this.state
    const { onSubmit } = this.props
    event.preventDefault()
    onSubmit(JSON.parse(JSON.stringify(data)))
  }

  handleChange = (key: string, value: string) => {
    const { data } = this.state

    data[key] = value
    this.setState(data)
  }

  handleSelectChange(key: string, event: React.ChangeEvent<HTMLSelectElement>) {
    const { data } = this.state

    data[key] = event.target.value
    this.setState(data)
  }

  render() {
    const { data } = this.state
    const { initialValues, schema, submitText } = this.props
    return (
      <FormStyle onSubmit={this.handleSubmit}>
        {schema.map((s, index) => {
          if (s.visible === false) {
            return <></>
          }
          switch (s.type) {
            case 'textarea':
              return (
                <>
                  <Label key={`label${index}`} htmlFor={s.name}>
                    {s.label}
                  </Label>
                  {/*@ts-ignore*/}
                  <TextArea
                    key={`textArea${index}`}
                    defaultValue={initialValues[s.name]}
                    onChange={(event) => this.handleChange(s.name, event.target.value)}
                    value={data[s.name]}
                    id={s.name}
                    placeholder={s.placeholder}
                    {...s}
                  />
                </>
              )
            case 'select':
              return (
                <>
                  <Label htmlFor={s.name}>{s.label}</Label>
                  <Select
                    id={s.name}
                    name={s.name}
                    style={{ color: data[s.name] ? 'black' : '#888888' }}
                    onChange={(e) => this.handleSelectChange(s.name, e)}
                  >
                    <option style={{ display: 'none' }} value="">
                      {s.placeholder}
                    </option>
                    {s.options?.map((option, i) => (
                      <option key={`opt${i}`} value={option}>
                        {option}
                      </option>
                    ))}
                  </Select>
                </>
              )
            case 'checkbox':
              return (
                <div key={`checkbox${index}`} style={{ display: 'flex', marginTop: 20, marginBottom: 10 }}>
                  <Input
                    defaultValue={initialValues[s.name]}
                    style={{ padding: 10, height: 15, width: 25 }}
                    required
                    onChange={(event: any) => this.handleChange(s.name, event.target.checked)}
                    value={data[s.name]}
                    id={s.name}
                    type="checkbox"
                    placeholder={s.placeholder}
                  />
                  <Label style={{ marginLeft: 20, color: 'grey' }} htmlFor={s.name}>
                    {s.label}
                  </Label>
                </div>
              )
            default:
              return (
                <>
                  <Label key={`label${index}`} htmlFor={s.name}>
                    {s.label}
                  </Label>
                  {/*@ts-ignore*/}
                  <Input
                    key={`input${index}`}
                    defaultValue={initialValues[s.name]}
                    required
                    onChange={(event) => this.handleChange(s.name, event.target.value)}
                    value={data[s.name]}
                    id={s.name}
                    type={s.type || 'text'}
                    placeholder={s.placeholder}
                    {...s}
                  />
                </>
              )
          }
        })}
        <Button type="submit">{submitText}</Button>
      </FormStyle>
    )
  }
}

const FormStyle = styled.form`
  margin-right: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`

const Label = styled.label`
  font-weight: 500;
  margin-bottom: 5px;
  font-size: 14px;
`

const Input = styled.input`
  height: 50px;
  margin-bottom: 10px;
  border-width: 1px;
  border-color: #5c5c5c;
  padding-left: 10px;
  :focus {
    outline: black;
  }
`

const TextArea = styled.textarea`
  border-width: 1px;
  border-color: #5c5c5c;
  padding-left: 10px;
  height: 100px;
  font-size: 14px;
`

const Select = styled.select`
  height: 50px;
  margin-bottom: 10px;
  border-width: 1px;
  border-color: #5c5c5c;
  padding-left: 10px;
  :focus {
    outline: black;
  }
`

const Button = styled.button`
  margin-top: 10px;
  height: 50px;
  background-color: black;
  color: white;
  font-size: 16px;
  border-color: #5c5c5c;
  cursor: pointer;
  :focus {
    outline: black;
  }
`
