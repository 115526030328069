import React, { Component } from 'react'
import Loader from 'react-loader-spinner'

interface Props {
  center?: boolean
  showLogo?: boolean
  size?: number
  style?: React.CSSProperties
}

export default class Spinner extends Component<Props> {
  render() {
    const { size, center, showLogo, style } = this.props

    return (
      <div
        style={
          center
            ? {
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
                ...style,
              }
            : style
        }
      >
        {showLogo && (
          <img src={require('../assets/logo.svg')} className="logo" alt="logo" style={{ marginTop: -100 }} />
        )}
        <Loader type="TailSpin" color="#000" height={size ?? 70} width={size ?? 70} />
      </div>
    )
  }
}
