import React from 'react'
import { Participant, Track } from 'twilio-video'
import Publication from '../Publication/Publication'
import usePublications from '../../hooks/usePublications/usePublications'
import useVideoContext from '../../hooks/useVideoContext/useVideoContext'

interface ParticipantTracksProps {
  participant: Participant
  disableAudio?: boolean
  enableScreenShare?: boolean
  videoPriority?: Track.Priority | null
}

/*
 *  The object model for the Room object (found here: https://www.twilio.com/docs/video/migrating-1x-2x#object-model) shows
 *  that Participant objects have TrackPublications, and TrackPublication objects have Tracks.
 *
 *  The React components in this application follow the same pattern. This ParticipantTracks component renders Publications,
 *  and the Publication component renders Tracks.
 */

export default function ParticipantTracks({
  participant,
  disableAudio,
  enableScreenShare,
  videoPriority,
}: ParticipantTracksProps) {
  const { room } = useVideoContext()
  const publications = usePublications(participant)
  const isLocal = participant === room.localParticipant

  let filteredPublications

  if (enableScreenShare && publications.some((p) => p.trackName.includes('screen'))) {
    filteredPublications = publications.filter((p) => !p.trackName.includes('camera'))
  } else {
    filteredPublications = publications.filter((p) => !p.trackName.includes('screen'))
  }

  return (
    <>
      {filteredPublications.map((publication) => (
        <Publication
          key={publication.kind}
          publication={publication}
          participant={participant}
          isLocal={isLocal}
          // disableAudio={disableAudio}
          // videoPriority={videoPriority}
        />
      ))}
      {filteredPublications.filter((publication) => publication.kind === 'video').length === 0 && (
        <div
          style={{ height: 100, width: 150, backgroundColor: 'black', position: 'fixed', bottom: 0, right: 0 }}
        ></div>
      )}
    </>
  )
}
