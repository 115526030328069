import api from 'shared/api'
import AppStore from 'shared/AppStore'
import SyncClient from 'twilio-sync'
import ReactGA from 'react-ga'

export const connectRoom = async (selectedUser: string, connect: (token: string) => void) => {
  const { data } = await api.post<{ token: string }>('twilio_access_token', { room: selectedUser })
  if (data?.token) {
    ReactGA.event({
      category: 'Connection room',
      action: `${AppStore.loggedUser?.role || ''} connect to room: ${selectedUser}`,
    })
    connect(data.token)
    var syncClient = new SyncClient(data.token)
    syncClient
      .document(selectedUser)
      .then(function (document) {
        AppStore.document = document
        // Listen to updates on the Document
        document.on('updated', function (event) {
          try {
            switch (event.value.event) {
              case 'click':
                AppStore.selectProduct(event.value.openProduct.match)
                break
              case 'screenshot':
                if (AppStore.loggedUser.role === 'sale') {
                  AppStore.openVirtualScreenshot && AppStore.openVirtualScreenshot(event.value?.image?.base64)
                }
                break
              case 'move':
                if (AppStore.loggedUser.role === 'sale') {
                  if (AppStore.virtualTourMove) {
                    AppStore.virtualTourMove(event.value)
                  }
                }
                break
            }
          } catch (err) {
            console.error(err)
          }
        })
      })
      .then(function (updateResult) {
        console.log('The Document was successfully updated', updateResult)
      })
      .catch(function (error) {
        console.error('Unexpected error', error)
      })
  } else {
    return false
  }
}

export const getLoggedUsername = () => {
  const userId = AppStore.loggedUser ? AppStore.loggedUser.username : 'not_invited'
  return userId.replace(/@/g, '_')
}

export const sendDataLayerPush = (obj: any) => {
  //@ts-ignore
  dataLayer.push({
    userID: AppStore.loggedUser?.username,
    userLevel: AppStore.loggedUser?.role, //user, sales, admin
    ...obj,
  })
}
