import React from 'react'
import { Page } from 'components'
import styled from 'styled-components'
import { getQueryParams, navigate } from 'shared/router'

const AppVideo = styled.video`
  width: 100%;
  align-self: center;
`

export default function Video() {
  const { videoUrl, title, poster } = getQueryParams()
  console.log(poster)
  return (
    <Page onBackPress={() => navigate('/')} title={title}>
      <div style={{ width: '98%', alignSelf: 'center', display: 'flex', flex: 1 }}>
        <AppVideo controls poster={poster}>
          <source src={videoUrl} type="video/mp4" />
        </AppVideo>
      </div>
    </Page>
  )
}
