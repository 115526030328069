import React, { Component } from 'react'
import { reloadApp, navigate } from '../shared/router'
import AppStore from '../shared/AppStore'
import { routes } from '../pages'
import api from '../shared/api'
import styled from 'styled-components'
import logo from 'assets/logo.svg'

interface Props {
  visible: boolean
  closeMenu: () => void
}

export default class Menu extends Component<Props> {
  logout = async () => {
    if (AppStore.loginType === 'login') {
      api.get('/logout')
      AppStore.removeToken()
      reloadApp()
    } else {
      const id = '65440f38-d30a-4085-b25c-641513925248'
      window.location.assign(
        `https://login.microsoftonline.com/${id}/oauth2/logout?post_logout_redirect_uri=${window.location.origin}/token`
      )
    }
  }

  openPage = (path: routes, params?: any) => {
    navigate(path, params)
    this.props.closeMenu()
  }

  render() {
    return (
      <>
        {this.props.visible && (
          <div className="menu-modal">
            <div style={{ flex: 1, overflowY: 'auto' }}>
              <div style={{ display: 'flex', borderBottom: '1px solid #E5E5E5', marginBottom: 20 }}>
                <Logo style={{ marginLeft: 20 }} className="logo-header" src={logo} alt="DG Logo" />
                <div style={{ flex: 1 }} />
                <img
                  src={require('../assets/close.svg')}
                  alt="close"
                  className="menu-close-icon"
                  onClick={this.props.closeMenu}
                />
              </div>
              <div className="menu-content">
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                  <div className="menu-item" onClick={() => this.openPage('/')}>
                    HOME
                  </div>
                  <div className="menu-item" onClick={() => this.openPage('/store-selector')}>
                    VIRTUAL BOUTIQUE
                  </div>
                  <div
                    className="menu-item"
                    onClick={() => {
                      this.props.closeMenu()
                      AppStore.videoModal &&
                        AppStore.videoModal({
                          title: '#DGNextChapter',
                          poster: 'https://d1jf4qsg0hj30w.cloudfront.net/tvs/DG_NEXT_CHAPTER.jpg',
                          video: 'https://d1jf4qsg0hj30w.cloudfront.net/tvs/DG_NEXT_CHAPTER.mp4',
                        })
                    }}
                  >
                    #DGNextChapter
                  </div>
                  <div
                    className="menu-item"
                    onClick={() => {
                      this.props.closeMenu()
                      AppStore.videoModal &&
                        AppStore.videoModal({
                          title: '#DGTogether',
                          poster: 'https://d1jf4qsg0hj30w.cloudfront.net/tvs/DG_TOGETHER.jpg',
                          video: 'https://d1jf4qsg0hj30w.cloudfront.net/tvs/DG_TOGETHER.mp4',
                        })
                    }}
                  >
                    #DGTogether
                  </div>
                  <div className="menu-item" onClick={() => this.openPage('/history-store')}>
                    RELIVE THE VIRTUAL BOUTIQUES
                  </div>
                  {!AppStore.loggedUser && (
                    <div className="menu-item" onClick={() => this.openPage('/book-appointment')}>
                      BOOK AN APPOINTMENT
                    </div>
                  )}
                  <div className="menu-item" onClick={() => this.openPage('/store-locator')}>
                    STORE LOCATOR
                  </div>
                  <div className="separator" />
                  {AppStore.loggedUser && AppStore.loggedUser.role === 'sale' && (
                    <div>
                      <div className="menu-item" onClick={() => this.openPage('/customers')}>
                        CUSTOMERS
                      </div>
                      <div className="menu-item" onClick={() => this.openPage('/notifications')}>
                        NOTIFICATIONS
                      </div>
                    </div>
                  )}
                  {AppStore.loggedUser && AppStore.loggedUser.role === 'customer' && (
                    <div className="menu-item" onClick={() => this.openPage('/mail-order')}>
                      WISHLIST
                    </div>
                  )}

                  {!AppStore.loggedUser && (
                    <div className="menu-item" onClick={() => this.openPage('/login')}>
                      LOGIN
                    </div>
                  )}

                  {AppStore.loggedUser && (
                    <div className="menu-item" onClick={this.logout}>
                      LOGOUT
                      {AppStore.loggedUser && (
                        <LoggedAs>LOGGED AS: {AppStore.loggedUser.username.toUpperCase()}</LoggedAs>
                      )}
                    </div>
                  )}
                  <div className="separator" />
                </div>
                <div className="menu-footer">
                  <div style={{ marginBottom: 20 }}>
                    Copyright © Dolce & Gabbana s.r.l. 2021
                    <br />
                    All rights reserved.
                    <br />
                    Any reproduction of the contents
                    <br />
                    is strictly forbidden.
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    )
  }
}

const Logo = styled.img`
  cursor: pointer;
`

const LoggedAs = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  /* or 40px */

  letter-spacing: 0.05em;
  line-height: 10px;
  color: #888888;
`
