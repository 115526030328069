import React, { useEffect, useState } from 'react'
import { Page, Box } from 'components'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { navigate } from 'shared/router'
import api from 'shared/api'

export type HistoryStore = { title: string; image: string; code: string; url: string }

const Body = styled.div`
  align-self: center;
  width: 600px;
  @media (max-width: 600px) {
    width: 100%;
  }
`

export default function HistoryStore() {
  const { pathname } = useLocation()
  const [loading, setloading] = useState(true)
  const [historyStores, sethistoryStores] = useState<HistoryStore[]>([])

  const fetchStores = async () => {
    try {
      let res = await api.get<any[]>(`history-stores`)
      if (res.data) sethistoryStores(res.data)
    } catch (e) {}
    setloading(false)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    fetchStores()
  }, [pathname])

  return (
    <Page title="Home" loading={loading} onBackPress={() => navigate('/')}>
      <Body>
        {historyStores.map((historyStore) => (
          <Box
            onClick={() => navigate('/virtual-tour/:storecode', { storecode: historyStore.code })}
            title={historyStore.title}
            img={historyStore.image}
          />
        ))}
      </Body>
    </Page>
  )
}
