import React, { useEffect, useState } from 'react'
import { Box, Page } from 'components'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { navigate } from 'shared/router'
import AppStore from 'shared/AppStore'
import useRoomState from 'twilio/hooks/useRoomState/useRoomState'
import { connectRoom, sendDataLayerPush } from 'utils'
import useVideoContext from 'twilio/hooks/useVideoContext/useVideoContext'
import useLocalAudioToggle from 'twilio/hooks/useLocalAudioToggle/useLocalAudioToggle'
import useLocalVideoToggle from 'twilio/hooks/useLocalVideoToggle/useLocalVideoToggle'
import api from 'shared/api'
import { toast } from 'react-toastify'

export default function Home() {
  const { connect, startTrack } = useVideoContext()
  const { pathname } = useLocation()
  const roomState = useRoomState()
  const [loading, setloading] = useState(true)
  const [store, setStore] = useState<any[]>()
  const [videoConnecting, setvideoConnecting] = useState<boolean>(false)
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle()
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle()

  const fetchStores = async () => {
    try {
      let res = await api.get<any[]>(`stores`)
      if (res.data) setStore(res.data)
    } catch (e) {}
    setloading(false)
  }

  // Use Title and Wrapper like any other React component – except they're styled!
  useEffect(() => {
    window.scrollTo(0, 0)
    fetchStores()
  }, [pathname])

  const onAppointmentClick = async () => {
    if (!AppStore.loggedUser) return navigate('/book-appointment')
    if (AppStore.loggedUser?.role === 'sale') return navigate('/customers')
    if (AppStore.loggedUser?.role === 'customer' && roomState === 'disconnected') {
      setvideoConnecting(true)
      await startTrack()
      await connectRoom(AppStore.loggedUser?.username, connect)
      if (!isAudioEnabled) toggleAudioEnabled()
      if (!isVideoEnabled) toggleVideoEnabled()
      sendDataLayerPush({ event: 'vb-appointment', eventAction: 'videocall' })
    }
  }

  const onVirtualBoutiqueClick = () => {
    if (store?.length === 1) {
      navigate(`/virtual-tour/:storecode`, { storecode: store[0].code })
    } else if (store?.length === 0) {
      toast.error('Not available at this moment')
    } else navigate('/store-selector')
  }

  let videoChatLabel = AppStore.loggedUser ? (
    AppStore.loggedUser.role === 'sale' ? (
      'Access To Customer List'
    ) : (
      <>
        Access To Video Call <br />
        <div style={{ fontSize: 10 }}>
          Wait a few seconds for <br />
          your client advisor to connect.
        </div>
      </>
    )
  ) : (
    'Book an appointment'
  )

  if (videoConnecting) {
    videoChatLabel = 'Connecting ...'
  }
  if (AppStore.loggedUser?.role === 'customer' && roomState === 'connected') {
    videoChatLabel = 'Video Call Connected'
  }
  if (roomState === 'connected' && videoConnecting) setvideoConnecting(false)

  const dashboardButtons = [
    {
      onClick: onVirtualBoutiqueClick,
      title: 'New virtual boutique Experience Coming Soon',
      img: 'https://d1jf4qsg0hj30w.cloudfront.net/tvs/dolce_gabbana_digital_boutique_b.gif',
    },
    // {
    //   title: (
    //     <>
    //       NEW VIRTUAL BOUTIQUE EXPERIENCE
    //       <br /> <i>COMING SOON</i>
    //     </>
    //   ),
    //   img: 'https://d1jf4qsg0hj30w.cloudfront.net/tvs/STORE_COMING_SOON.jpg',
    // },
    {
      onClick: onAppointmentClick,
      title: videoChatLabel,
      img: AppStore.loggedUser
        ? 'https://d1jf4qsg0hj30w.cloudfront.net/tvs/dolce_gabbana_video_call_a.jpg'
        : 'https://d1jf4qsg0hj30w.cloudfront.net/tvs/dolce_gabbana_book_appointment_a.jpg',
    },
    {
      onClick: () =>
        AppStore.videoModal &&
        AppStore.videoModal({
          title: 'Women\'s FW22 Fashion Show',
          poster: 'https://d1jf4qsg0hj30w.cloudfront.net/tvs/VB-WEB-APP-Virtual-Boutique.jpg',
          video: 'https://d1jf4qsg0hj30w.cloudfront.net/tvs/videos/DG_WFW22-23_FULL_EDIT_LINKEDIN.mp4',
        }),
      title: 'Women\'s FW22 Fashion Show',
      img: 'https://d1jf4qsg0hj30w.cloudfront.net/tvs/VB-WEB-APP-Virtual-Boutique.jpg',
      backgroundPositionY: '20%',
    },
    {
      onClick: () =>
        AppStore.videoModal &&
        AppStore.videoModal({
          title: 'Men\'s FW22 Fashion Show',
          poster: 'https://d1jf4qsg0hj30w.cloudfront.net/tvs/banner-video-FW22-MAN.jpg',
          video: 'https://d1jf4qsg0hj30w.cloudfront.net/tvs/videos/DG_FW22_FULL_SOCIAL.mp4',
        }),

      title: 'Men\'s FW22 Fashion Show',
      img: 'https://d1jf4qsg0hj30w.cloudfront.net/tvs/banner-video-FW22-MAN.jpg',
    },
    {
      onClick: () => navigate('/history-store'),
      title: 'Relive the virtual boutiques',
      img: 'https://d1jf4qsg0hj30w.cloudfront.net/tvs/dolce_gabbana_relive_boutique_a.jpg',
    },
    {
      onClick: () => navigate('/store-locator'),
      title: 'Store Locator',
      img: 'https://d1jf4qsg0hj30w.cloudfront.net/tvs/dolce_gabbana_store_locator_a.jpg',
    },
  ]

  return (
    <Page title="Home" hideBorderHeader loading={loading}>
      <Body>
        {dashboardButtons.map((button) => (
          <Box {...button} />
        ))}
      </Body>
    </Page>
  )
}

const Body = styled.div`
  align-self: center;
  width: 600px;
  @media (max-width: 600px) {
    width: 100%;
  }
`
