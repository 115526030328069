import React, { useEffect, useState } from 'react'
import { Page, Spinner } from 'components'
import { navigate } from 'shared/router'
import styled from 'styled-components'
import api from 'shared/api'
import { Notification, User } from 'types'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { TextField } from '@material-ui/core'

export default function Notifications() {
  const [loading, setloading] = useState(true)
  const [notifications, setnotifications] = useState<Notification[]>([])
  const [customers, setcustomers] = useState<User[]>([])
  const [selectedCustomer, setselectedCustomer] = useState<User | undefined>(undefined)

  useEffect(() => {
    async function init() {
      let customerRes = await api.get<User[]>('users')
      setcustomers(customerRes.data || ([] as User[]))
      await refreshNotifications()
      setloading(false)
    }
    init()
    // eslint-disable-next-line
  }, [])

  const refreshNotifications = async () => {
    if (window.location.pathname === '/notifications') {
      let notificationRes = await api.get<Notification[]>('notifications')
      if (notificationRes.ok) {
        setnotifications(notificationRes.data || ([] as Notification[]))
      }

      setTimeout(() => {
        refreshNotifications()
      }, 3000)
    }
  }

  const openNotification = (notification: Notification) => {
    try {
      if (notification.type?.includes('_wishlist_add_')) {
        const customer_id = notification.type?.split('_wishlist_add_')[0]
        // const sku = notification.type?.split('_wishlist_add_')[1]
        let customer = customers.find((c) => c.id === parseInt(customer_id))

        if (customer) navigate('/mail-order/:username', { username: customer.username })
      }
    } catch (error) {
      console.error(error)
    }
  }

  let content = <Spinner center />
  let notificationsFiltered

  if (selectedCustomer) notificationsFiltered = notifications.filter((n) => n.user_id === selectedCustomer.id)
  else notificationsFiltered = notifications

  if (!loading) {
    content = (
      <Body>
        <H1>Notifications</H1>
        <Autocomplete
          style={{ margin: 10 }}
          id="combo-box-demo"
          value={selectedCustomer}
          onChange={(event: any, newValue: User | null) => {
            setselectedCustomer(newValue || undefined)
          }}
          options={customers}
          getOptionLabel={(option) => option.username}
          renderInput={(params) => <TextField {...params} label="Customer" variant="outlined" />}
        />
        {notificationsFiltered && notificationsFiltered.length > 0 ? (
          notificationsFiltered.slice(0, 50).map((notification) => {
            let datetime = new Date(notification.created_at)
            return (
              <>
                <div className="separator" />
                <Card onClick={() => openNotification(notification)}>
                  <Description>{notification.name}</Description>
                  <DateLabel>
                    {datetime.toLocaleDateString()} <br />
                    {datetime.toLocaleTimeString()}
                  </DateLabel>
                </Card>
              </>
            )
          })
        ) : (
          <Empty>No notifications found</Empty>
        )}
      </Body>
    )
  }

  return (
    <Page onBackPress={() => navigate('/')} title="Notifications">
      {content}
    </Page>
  )
}

const Body = styled.div`
  align-self: center;
  width: 600px;
  @media (max-width: 600px) {
    width: 100%;
  }
`

const Card = styled.div<{ isRead?: boolean }>`
  transition: 0.3s;
  padding-left: 20px;
  padding-right: 20px;
  height: 50px;
  display: flex;
  cursor: pointer;
  position: relative;
  background-color: ${(props) => (props.isRead ? '#c8e6c9' : 'transparent')};
`

const H1 = styled.div`
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
`

const DateLabel = styled.div`
  text-align: right;
  font-weight: 500;
  font-size: 12px;
  top: 5px;
  right: 10px;
  color: #888888;
  display: flex;
  align-items: center;
`

const Empty = styled.div`
  text-align: center;
  font-weight: normal;
  font-size: 20px;
  margin-top: 60px;
  margin-bottom: 20px;
`

const Description = styled.div`
  flex: 1;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
`
