import React, { useEffect, useRef, useState } from 'react'
import { Page, Spinner } from 'components'
import styled from 'styled-components'
import { navigate, getMatchParams, navigateBack } from 'shared/router'
import AppStore from 'shared/AppStore'
import { sendDataLayerPush } from 'utils'
import api from 'shared/api'
import Button from 'components/Button'

const IFrame = styled.iframe<{ loading: boolean; isHistory: boolean }>`
  border-width: 0px;
  max-width: 100vw;
  height: 100%;
  flex: ${(props) => (props.loading ? '0' : '1')};
`

export default function VirtualTour(props: any) {
  const [loadingFrame, setloadingFrame] = useState(true)
  const [loading, setloading] = useState(true)
  const [store, setstore] = useState<any>()
  const [isHistory, setIsHistory] = useState(false)
  const { storecode } = getMatchParams(props)
  const iframe = useRef<any>()
  const iFrameContainerHeight = props.loading ? '0px' : (props.isHistory ? 'calc(100vh - 140px)' : 'calc(100vh - 60px)')

  const navigateVirtualStore = (e: any) => {
    try {
      iframe.current.contentWindow.postMessage(JSON.stringify(e), '*')
    } catch (error) {
      console.error(error)
    }
  }

  // Use Title and Wrapper like any other React component – except they're styled!
  useEffect(() => {
    const onLoad = async () => {
      try {
        let currentStoresList = (await api.get<any[]>(`stores`)).data
        let historyStoresList = (await api.get<any[]>(`history-stores`)).data
        let storesList = currentStoresList?.concat(historyStoresList)
        let indexStore = storesList && storesList.findIndex((store) => store.code === storecode)
        if (indexStore === undefined || indexStore === -1) {
          navigate('/')
          return
        } else {
          if (currentStoresList && indexStore >= currentStoresList.length) setIsHistory(true)
          setstore(storesList![indexStore!])
          AppStore.tourLanguage = storesList![indexStore!].language
          setloading(false)
        }
      } catch (e) {
        navigate('/')
        return
      }

      AppStore.virtualTourMove = navigateVirtualStore

      const handler = (event: any) => {
        try {
          let eventObj = JSON.parse(event.data)
          switch (eventObj.event) {
            case 'screenshot':
              if (AppStore.loggedUser?.role === 'customer') {
                AppStore.document && AppStore.document.set(eventObj)
              }
              break
            case 'move':
              if (AppStore.loggedUser?.role === 'customer') {
                AppStore.document && AppStore.document.set(eventObj)
              }
              break
            case 'click':
              const sku = eventObj.openProduct.match
              if (sku) {
                sendDataLayerPush({ event: 'vb-viewproduct', 'vb-sku': sku })
                AppStore.document ? AppStore.document.set(eventObj) : AppStore.selectProduct(sku)
              }
              break
          }
        } catch (error) {}
      }

      window.addEventListener('message', handler)
    }

    onLoad()
  }, [storecode])



  return (
    <Page hideFooter onBackPress={() => navigateBack()} title="Virtual Tour" loading={loading}>
      {loadingFrame && <Spinner center />}
      {store && (
        <div style={{ display: 'flex', flexDirection: 'column', height: iFrameContainerHeight, flex: (loading ? '0' : '1') }} >
          <IFrame
            isHistory={isHistory}
            ref={iframe}
            loading={loadingFrame}
            onLoad={() => {
              setloadingFrame(false)
              window.scrollTo(0, 9999)
            }}
            src={store.url}
          />
          {isHistory && (
            <Button
              style={{ margin: 10 }}
              onClick={() => {
                navigate('/book-appointment')
              }}
            >
              Book an appointment
            </Button>
          )}
        </div>
      )}
    </Page>
  )
}
