import React from 'react'
import { navigate } from 'shared/router'
import styled from 'styled-components'
import logo from 'assets/logo.svg'

interface Props {
  onBackPress?: () => void
  iconColor?: string
  onMenuPress: () => void
  showMenuButton?: boolean
  hideBorderHeader?: boolean
}

export default function Header({
  onBackPress,
  iconColor = 'black',
  hideBorderHeader = false,
  onMenuPress,
  showMenuButton = true,
}: Props) {
  return (
    <>
      <HeaderStyle style={hideBorderHeader ? { borderBottom: '0px' } : { borderBottom: '1px solid #e5e5e5' }}>
        {!!onBackPress && (
          <div className="menu-button menu-button-left" onClick={onBackPress}>
            <svg width="18" height="18" viewBox="0 0 27 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12.1883 22L1.9162 11.7279L12.1883 1.45581M2.10011 11.895H26.3117"
                stroke={iconColor}
                strokeWidth="1.61411"
              />
            </svg>
          </div>
        )}

        {showMenuButton && (
          <div className="menu-button menu-button-right" onClick={onMenuPress}>
            <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="1" y="11" width="15" height="1" fill="black" stroke="black" />
              <rect x="1" y="6" width="15" height="1" fill="black" stroke="black" />
              <rect x="1" y="1" width="15" height="1" fill="black" stroke="black" />
            </svg>
          </div>
        )}
        <Logo
          style={{ marginLeft: onBackPress ? 50 : 20 }}
          className="logo-header"
          onClick={() => navigate('/')}
          src={logo}
          alt="DG Logo"
        />
      </HeaderStyle>
      <Spacer />
    </>
  )
}

const HeaderStyle = styled.div`
  height: 60px;
  z-index: 1;
  display: flex;
  background-color: white;
  position: fixed;
  top: 0;
  width: 100%;
`

const Spacer = styled.div`
  height: 60px;
`

const Logo = styled.img`
  cursor: pointer;
`
