import React, { Component } from 'react'
import api from 'shared/api'
import styled from 'styled-components'
import Video from 'twilio-video'

interface Props {}

interface State {
  identity?: any /* Will hold the fake name assigned to the client. The name is generated by faker on the server */
  roomName: string /* Will store the room name */
  roomNameErr: boolean /* Track error for room name TextField. This will    enable us to show an error message when this variable is true */
  previewTracks: any
  localMediaAvailable: boolean /* Represents the availability of a LocalAudioTrack(microphone) and a LocalVideoTrack(camera) */
  hasJoinedRoom: boolean
  activeRoom?: Video.Room // Track the current active room
  token?: string
}

export default class VideoComponent extends Component<Props, State> {
  Video = styled.video`
    max-width: '40vw';
    max-height: '35vh';
    object-fit: contain;
  `

  resetState = () => {
    this.setState({
      identity: undefined /* Will hold the fake name assigned to the client. The name is generated by faker on the server */,
      roomName: 'test1' /* Will store the room name */,
      roomNameErr: false /* Track error for room name TextField. This will    enable us to show an error message when this variable is true */,
      previewTracks: undefined,
      localMediaAvailable: false /* Represents the availability of a LocalAudioTrack(microphone) and a LocalVideoTrack(camera) */,
      hasJoinedRoom: false,
      activeRoom: undefined,
    })
  }

  state: State = {
    identity: undefined /* Will hold the fake name assigned to the client. The name is generated by faker on the server */,
    roomName: 'test1' /* Will store the room name */,
    roomNameErr: false /* Track error for room name TextField. This will    enable us to show an error message when this variable is true */,
    previewTracks: undefined,
    localMediaAvailable: false /* Represents the availability of a LocalAudioTrack(microphone) and a LocalVideoTrack(camera) */,
    hasJoinedRoom: false,
    activeRoom: undefined,
  }

  async componentDidMount() {
    const token = (await api.get<string>('twilio_access_token')).data
    this.setState({ token })
  }

  handleRoomNameChange = (e: any) => {
    /* Fetch room name from text field and update state */
    let roomName = e.target.value
    this.setState({ roomName })
  }

  joinRoom = () => {
    const { token } = this.state
    /* 
 Show an error message on room name text field if user tries         joining a room without providing a room name. This is enabled by setting `roomNameErr` to true
   */
    if (!token) {
      return this.setState({ roomNameErr: true })
    }
    if (!this.state.roomName.trim()) {
      this.setState({ roomNameErr: true })
      return
    }

    console.log("Joining room '" + this.state.roomName + "'...")
    let connectOptions: Video.ConnectOptions = {
      name: this.state.roomName,
      video: { height: 480, frameRate: 24, width: 640 },
      networkQuality: { local: 1, remote: 1 },
    }

    if (this.state.previewTracks) {
      connectOptions.tracks = this.state.previewTracks
    }

    /* 
 Connect to a room by providing the token and connection    options that include the room name and tracks. We also show an alert if an error occurs while connecting to the room.    
 */

    Video.connect(token, connectOptions).then(this.roomJoined, (error) => {
      alert('Could not connect to Twilio: ' + error.message)
    })
  }

  // Attach the Tracks to the DOM.
  attachTracks = (tracks: any, container: any) => {
    tracks.forEach((track: any) => {
      console.log(track)
      container.appendChild(track.track.attach())
    })
  }

  // Attach the Participant's Tracks to the DOM.
  attachParticipantTracks = (participant: any, container: any) => {
    var tracks = Array.from(participant.tracks.values())
    this.attachTracks(tracks, container)
  }

  detachTracks(tracks: any) {
    tracks.forEach((track: any) => {
      let t = track
      if (track.track) t = track.track
      t.detach().forEach((detachedElement: any) => {
        detachedElement.remove()
      })
    })
  }

  detachParticipantTracks(participant: any) {
    var tracks = Array.from(participant.tracks.values())
    this.detachTracks(tracks)
  }

  roomJoined = (room: any) => {
    // Called when a participant joins a room
    console.log("Joined as '" + this.state.identity + "'")
    this.setState({
      activeRoom: room,
      localMediaAvailable: true,
      hasJoinedRoom: true, // Removes ‘Join Room’ button and shows ‘Leave Room’
    })

    // Attach LocalParticipant's tracks to the DOM, if not already attached.
    var previewContainer = this.refs.localMedia
    //@ts-ignore
    if (!previewContainer.querySelector('video')) {
      this.attachParticipantTracks(room.localParticipant, previewContainer)
    }

    // Attach the Tracks of the room's participants.
    room.participants.forEach((participant: any) => {
      console.log("Already in Room: '" + participant.identity + "'")
      var previewContainer = this.refs.remoteMedia
      this.attachParticipantTracks(participant, previewContainer)
    })

    // Participant joining room
    room.on('participantConnected', (participant: any) => {
      console.log("Joining: '" + participant.identity + "'")
    })

    // Attach participant’s tracks to DOM when they add a track
    room.on('trackAdded', (track: any, participant: any) => {
      console.log(participant.identity + ' added track: ' + track.kind)
      var previewContainer = this.refs.remoteMedia
      this.attachTracks([track], previewContainer)
    })

    // Detach participant’s track from DOM when they remove a track.
    room.on('trackRemoved', (track: any, participant: any) => {
      // this.log(participant.identity + ' removed track: ' + track.kind)
      this.detachTracks([track])
    })

    // Detach all participant’s track when they leave a room.
    room.on('participantDisconnected', (participant: any) => {
      console.log("Participant '" + participant.identity + "' left the room")
      this.detachParticipantTracks(participant)
    })

    // Once the local participant leaves the room, detach the Tracks
    // of all other participants, including that of the LocalParticipant.
    room.on('disconnected', () => {
      if (this.state.previewTracks) {
        this.state.previewTracks.forEach((track: any) => {
          track.stop()
        })
      }
      this.detachParticipantTracks(room.localParticipant)
      room.participants.forEach(this.detachParticipantTracks)
      this.setState({ activeRoom: undefined, hasJoinedRoom: false, localMediaAvailable: false })
    })
  }

  leaveRoom = () => {
    const { activeRoom } = this.state
    if (activeRoom) {
      activeRoom.disconnect()
      this.resetState()
    }
  }

  render() {
    /* 
    Controls showing of the local track
    Only show video track after user has joined a room else show nothing 
   */
    let showLocalTrack = this.state.localMediaAvailable ? (
      <div className="flex-item">
        <div ref="localMedia" />
      </div>
    ) : (
      ''
    )
    /*
    Controls showing of ‘Join Room’ or ‘Leave Room’ button.  
    Hide 'Join Room' button if user has already joined a room otherwise 
    show `Leave Room` button.
   */
    let joinOrLeaveRoomButton = this.state.hasJoinedRoom ? (
      <button onClick={this.leaveRoom}>Leave Room</button>
    ) : (
      <button onClick={this.joinRoom}>Join Room</button>
    )
    return (
      <div>
        <div className="flex-container">
          {showLocalTrack} {/* Show local track if available */}
          <div className="flex-item">
            {/* 
 The following text field is used to enter a room name. It calls  `handleRoomNameChange` method when the text changes which sets the `roomName` variable initialized in the state.
     */}
            {/* <TextField
                hintText="Room Name"
                onChange={this.handleRoomNameChange}
                errorText={this.state.roomNameErr ? 'Room Name is required' : undefined}
              /> */}
            <br />
            {joinOrLeaveRoomButton} {/* Show either ‘Leave Room’ or ‘Join Room’ button */}
          </div>
          {/* 
 The following div element shows all remote media (other                             participant’s tracks) 
     */}
          <div className="flex-item" ref="remoteMedia" id="remote-media" />
        </div>
      </div>
    )
  }
}
