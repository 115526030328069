import { Language } from './AppStore'

export function getTranslationText(translations: any[] = [], key: string, lang: Language) {
  return translations.find((t) => t.key === key && t.lang.toLowerCase() === lang.toLowerCase())?.text
}

export function snakeCase(text: string) {
  text = text.replace(/\W+/g, ' ').toLowerCase().split(' ').join('_')

  if (text.charAt(text.length - 1) === '_') {
    return text.substring(0, text.length - 1)
  }

  return text
}
