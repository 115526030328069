export default {
  wishlist: {
    wishlist_empty: 'La tua wishlist è vuota.',
    button_send_wishlist: 'Condividi la tua wishlist',
    wishlist_sent_message: 'La tua wishlist è stata inviata.',
  },
  countdown: {
    intro_text:
      'Siamo lieti di invitarvi a un nuovo viaggio alla scoperta della bellezza italiana.\nUn’anteprima sarà qui disponibile in esclusiva per voi.\nA presto',
  },
  misc: {
    go_home: 'Torna alla home page',
    disclaimer: 'Chiediamo gentilmente di non condividere nessun contenuto sui canali social fino a venerdì 3 luglio.',
    close: 'Chiudi',
  },
}
