import React, { useState, useEffect } from 'react'
import { Router, Switch, Route, Redirect } from 'react-router-dom'
import { PublicRoutes } from 'pages'
import { history, navigate } from 'shared/router'
import AppStore from 'shared/AppStore'
import { ModalCenter, ProductCard, Spinner } from 'components'
import styled from 'styled-components'
import Controls from 'twilio/components/Controls/Controls'
import ReconnectingNotification from 'twilio/components/ReconnectingNotification/ReconnectingNotification'
import useRoomState from 'twilio/hooks/useRoomState/useRoomState'
import MainParticipant from 'twilio/components/MainParticipant/MainParticipant'
import { ToastContainer, Slide } from 'react-toastify'
import api from 'shared/api'
import ReactGA from 'react-ga'
import { getLoggedUsername } from 'utils'
import OfflinePage from 'pages/OfflinePage'
import { hot } from 'react-hot-loader/root'
import Button from 'components/Button'
import { VideoModal } from 'types'

const trackingId = 'UA-173488317-1' // Replace with your Google Analytics tracking ID

document.addEventListener('gesturestart', function (e) {
  e.preventDefault()
})

const Main = styled('main')({
  // overflow: 'scroll',
  position: 'fixed',
  maxHeight: '30vh',
  // minHeight: 100,
  // minWidth: 180,
  bottom: 0,
  right: 0,
  zIndex: 9999,
})

export const App: React.FC = () => {
  const [loading, setLoading] = useState(true)
  const [productSelected, setproductSelected] = useState<any | undefined>(undefined)
  const [base64ScreenshootUrl, setbase64ScreenshootUrl] = useState<string | undefined>(undefined)
  const [videoModal, setVideoModal] = useState<VideoModal | undefined>(undefined)

  const roomState = useRoomState()

  const offline = false

  useEffect(() => {
    async function updateProductSelected(code?: any) {
      if (!productSelected || productSelected.code !== code) {
        const res = await api.get('products/' + code)
        if (res.ok) setproductSelected(res.data)
      }
    }

    async function updateVirtualStoreScreenshot(base64Screenshot?: string) {
      if (AppStore.loggedUser?.role === 'sale' && !!base64Screenshot && window.location.pathname === '/customers') {
        setbase64ScreenshootUrl(base64Screenshot)
      }
    }

    async function init() {
      AppStore.selectProduct = updateProductSelected
      AppStore.openVirtualScreenshot = updateVirtualStoreScreenshot
      AppStore.videoModal = (videoModal: VideoModal) => setVideoModal(videoModal)

      ReactGA.initialize(trackingId)
      ReactGA.set({
        userId: getLoggedUsername(),
      })
      ReactGA.event({
        category: 'Open App',
        action: `Connected as: ${getLoggedUsername()}`,
      })

      if ('geolocation' in navigator && !AppStore.loggedUser) {
        navigator.geolocation.getCurrentPosition(function (position) {
          api
            .get<any>(`location?lat=${position.coords.latitude}&lng=${position.coords.longitude}`)
            .then((location) => {
              AppStore.geolocation = { ...location.data, coords: position.coords, timestamp: position.timestamp }
            })
            .catch((error) => {
              console.error('Geonames fail to retrieve position', error)
            })
        })
      } else {
        console.error('Geolocation not available')
      }

      setLoading(false)
    }

    //App online
    if (!offline) {
      loading === true && init()

      AppStore.roomState = roomState
    } else {
      setLoading(false)
    }
  }, [roomState, loading, offline, productSelected])

  if (loading) return <Spinner showLogo center />

  //App is offline
  if (offline) return <OfflinePage />

  return (
    <Router history={history}>
      <Switch>
        {Object.entries(PublicRoutes).map(([path, route]) => (
          <Route path={path} component={route.component} key={path} exact={true} />
        ))}
        <Redirect to={'/'} />
      </Switch>
      {roomState !== 'disconnected' && (
        <Main>
          <MainParticipant />
          <Controls />
        </Main>
      )}
      <ReconnectingNotification />

      {!!productSelected && (
        <ModalCenter open={!!productSelected} onClose={() => setproductSelected(undefined)}>
          <ProductCard
            closeFn={() => setproductSelected(undefined)}
            product={productSelected}
            setProduct={setproductSelected}
          />
        </ModalCenter>
      )}
      {!!base64ScreenshootUrl && (
        <ModalCenter
          modalStyle={{ maxWidth: '95vw' }}
          title={`Virtual Tour`}
          open={!!base64ScreenshootUrl}
          onClose={() => setbase64ScreenshootUrl(undefined)}
        >
          <img style={{ objectFit: 'contain', width: '95vw', maxHeight: '80vh' }} alt="" src={base64ScreenshootUrl} />
        </ModalCenter>
      )}
      {!!videoModal && (
        <ModalCenter
          title={videoModal.title}
          open={true}
          onClose={() => setVideoModal(undefined)}
          backgroundStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.9)' }}
          modalStyle={{ maxHeight: '100%' }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }} className={'WalkingStreetModal'}>
            <video autoPlay style={{ width: '100%', height: '100%' }} controls poster={videoModal.poster}>
              <source src={videoModal.video} type="video/mp4" />
            </video>
            <Button
              style={{ margin: 10 }}
              onClick={() => {
                setVideoModal(undefined)
                navigate('/book-appointment')
              }}
            >
              BOOK AN APPOINTMENT
            </Button>
          </div>
        </ModalCenter>
      )}
      <ToastContainer
        transition={Slide}
        position="top-center"
        autoClose={2500}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Router>
  )
}

export default hot(App)
