import React, { Component } from 'react'
import Menu from './Menu'
import { Spinner } from '.'
import { snakeCase } from 'shared/helpers'
import { routes } from 'pages'
import Header from './Header'
import { sendDataLayerPush } from 'utils'
import styled from 'styled-components'
// import { navigate } from 'shared/router'

// import AppStore from '../shared/AppStore'

interface Props {
  title?: string
  style?: React.CSSProperties
  onBackPress?: () => void
  backPage?: routes
  className?: string
  topBarLogoUrl?: string
  menuTheme?: 'light' | 'dark'
  showTitle?: boolean
  loading?: boolean
  showMenuButton?: boolean
  showHeader?: boolean
  hideBorderHeader?: boolean
  hideFooter?: boolean
}

export default class Page extends Component<Props> {
  static defaultProps: Partial<Props> = {
    showMenuButton: true,
    showHeader: true,
    hideFooter: false,
  }

  state = {
    showMenu: false,
  }

  static getDerivedStateFromProps(nextProps: Props) {
    if (!nextProps.title) return null

    const title = `VB Dolce&Gabbana - ${nextProps.title}`

    if (title !== document.title) {
      document.title = title

      sendDataLayerPush({ event: 'vb-realpage', 'vb-path': window.location.pathname })

      // @ts-ignore
      // dataLayer.push({ event: 'router-PV', path: window.location.pathname, userId: AppStore.loggedUser?.username })
    }

    return null
  }

  showMenu = () => this.setState({ showMenu: true })
  hideMenu = () => this.setState({ showMenu: false })

  render() {
    const { children, style, className, title, loading, onBackPress, showHeader, hideFooter } = this.props
    const { showMenu } = this.state

    return (
      <div className={`page ${className ? className : ''} ${title ? snakeCase(title) : ''}`} style={style}>
        {showHeader && (
          <Header
            hideBorderHeader={this.props.hideBorderHeader}
            onMenuPress={this.showMenu}
            onBackPress={onBackPress}
            showMenuButton={this.props.showMenuButton}
          />
        )}
        {showMenu && <Menu visible closeMenu={() => this.setState({ showMenu: false })} />}

        {!loading && children}
        {!hideFooter && (
          <Footer>
            Copyright © Dolce & Gabbana s.r.l. 2021
            <br />
            All rights reserved.
            <br />
            Any reproduction of the contents
            <br />
            is strictly forbidden.
            <br />
          </Footer>
        )}
        {loading && <Spinner center />}

        <Menu visible={this.state.showMenu} closeMenu={() => this.setState({ showMenu: false })} />
      </div>
    )
  }
}

const Footer = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 165%;
  /* or 20px */

  text-align: center;
  letter-spacing: 0.05em;

  margin: 30px 50px;
`
