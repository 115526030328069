import 'react-hot-loader'
import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import './assets/index.css'
import './assets/responsive.css'
import './index.css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-image-gallery/styles/css/image-gallery.css'

//fonts
import './assets/fonts/FuturaLTW05-Heavy.ttf'
import './assets/fonts/FuturaLTW05-Medium.ttf'

import App from './App'
import * as serviceWorker from './serviceWorker'
import UnsupportedBrowserWarning from 'twilio/components/UnsupportedBrowserWarning/UnsupportedBrowserWarning'
import AppStateProvider, { useAppState } from 'twilio/state'
import { VideoProvider } from 'twilio/components/VideoProvider'
import ErrorDialog from 'twilio/components/ErrorDialog/ErrorDialog'
import generateConnectionOptions from 'twilio/utils/generateConnectionOptions/generateConnectionOptions'
import AppStore from 'shared/AppStore'

const VideoApp = () => {
  const { error, setError, settings } = useAppState()
  const connectionOptions = generateConnectionOptions(settings)

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function init(){
      await AppStore.loadInitialData()
      setLoading(false)
    }
    init()
  }, [])
  if(loading) return<></>

  return (
    <UnsupportedBrowserWarning>
      <VideoProvider options={connectionOptions} onError={setError}>
        <ErrorDialog dismissError={() => setError(null)} error={error} />
        <App />
      </VideoProvider>
    </UnsupportedBrowserWarning>
  )
}

ReactDOM.render(
  <AppStateProvider>
    <VideoApp />
  </AppStateProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
