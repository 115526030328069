export default {
  wishlist: {
    wishlist_empty: 'Your wishlist is empty.',
    button_send_wishlist: 'Share your wishlist',
    wishlist_sent_message: 'Thank you. Your wishlist has been sent.',
  },
  countdown: {
    intro_text:
      'Welcome to our new journey through Italian Beauty. \nAn exclusive preview will soon be available on this website specially for you.\nLove',
  },
  misc: {
    go_home: 'Back to the home page',
    disclaimer: 'We kindly ask you to not share on your social media any of the following contents until July 3rd.',
    close: 'Close',
  },
}
